// @flow
import {JSONProvider} from './utils/jsonProvider';
import {APIProvider} from './utils/apiProvider';
import {Logger} from './utils/logger';
import {load, scripts} from './utils/scripts';
import {providerUrl} from './utils/config';
import {showLoading, hideLoading} from './components/loading';
import {
  defaultConfig,
  defaultInputConfig,
  assetsCDN,
  defaultAllowedDomains,
  geoIpReferrer,
  defaultUsersIvcAnalytics,
  hlsDvrConfig,
  hlsLiveConfig,
  hlsVodConfig,
  realtimeUrl,
  whiteListDomains,
  chatUrl,
  analyticsUrl,
  geoIpRealtime
} from './utils/config';
import _ from 'underscore';
import {VideoMeta} from './utils/videoMeta';
import {Error} from './utils/errors';
import {render as renderError} from './components/error';
import {Http} from './utils/http';
import './style.css';
import analytics from './engines/analytics';
import ads from './engines/ads';
import {getBrowserLocales} from './utils/locale';
import {customizePlayer} from './engines/customize';
import defaultJSON from './utils/defaultJSON';
// import {TVRemoteControl} from './engines/tv-remote-control';
import {FakeEvent} from '@playkit-js/playkit-js';

class PlayerManager {
  VERSION = __VERSION__;
  player: any;
  configInput: any;
  playerConfig: any;
  assetsCDN: any;
  analyticsUrl: any;
  realtimeUrl: any;
  geoIpRealtime: any;
  logger: Logger;
  videoMeta: VideoMeta;
  timeoutHover: any;
  // whiteListDomains: string[];
  defaultUsersIvcAnalytics: string[];

  constructor(configInput) {
    try {
      window['ivcVersions'] = [];
      console.log('%cInstantVideoCloud Player v' + __VERSION__, 'font-size:30px; background:#333333; color:#FF9000; padding:5px; border-radius:5px;');
      console.log(
        '%c WARNING! This browser feature is intended for developers. Unauthorized or non-expert use is not recommended.',
        'font-size:20px; background:#333333; color:red; padding:5px; border-radius:5px;'
      );
      console.log('%c Read more: (https://instantvideocloud.com)', 'font-size:15px; background:#333333; color:grey; padding:5px; border-radius:5px;');

      window['showIvcVersions'] = () => {
        console.log('InstantVideoCloud Player versions: ', window['ivcVersions']);
        for (let plugin of window['ivcVersions']) {
          console.log(`%c ${plugin.name} v${plugin.version}`, 'font-size:13px; background:#333333; color:#FF9000; padding:5px; border-radius:5px;');
        }
        return;
      };
    } catch (e) {
      // do nothing
    }

    this.logger = new Logger('PlayerManager');
    this.configInput = configInput;
    showLoading(configInput.targetId);
    this.mergeConfig();
    if (this.configInput.embed === null && (this.configInput.videoMeta === undefined || this.configInput.videoMeta == null)) this.urlParams();
    this.assetsCDN = configInput.assetsCDN !== undefined && configInput.assetsCDN ? configInput.assetsCDN : assetsCDN;
    this.realtimeUrl = configInput.realtimeUrl !== undefined && configInput.realtimeUrl ? configInput.realtimeUrl : realtimeUrl;
    this.whiteListDomains =
      configInput.whiteListDomains !== undefined && configInput.whiteListDomains ? configInput.whiteListDomains : whiteListDomains;
    this.chatUrl = configInput.chatUrl !== undefined && configInput.chatUrl ? configInput.chatUrl : chatUrl;
    this.analyticsUrl = configInput.analyticsUrl !== undefined && configInput.analyticsUrl ? configInput.analyticsUrl : analyticsUrl;
    this.geoIpRealtime = configInput.geoIpRealtime !== undefined && configInput.geoIpRealtime ? configInput.geoIpRealtime : geoIpRealtime;
    this.defaultUsersIvcAnalytics =
      configInput.defaultUsersIvcAnalytics !== undefined && configInput.defaultUsersIvcAnalytics
        ? configInput.defaultUsersIvcAnalytics
        : defaultUsersIvcAnalytics;
  }

  errorNotFound(e) {
    console.debug(e);
    this.error(Error.NOT_FOUND, null, Error.NOT_FOUND.text, `${this.assetsCDN}/images/${Error.NOT_FOUND.icon}`);
  }

  async init(startConfigure) {
    const canConfigure = startConfigure !== undefined && startConfigure !== null ? startConfigure : true;
    this.playerConfig = defaultConfig;
    this.playerConfig['targetId'] = this.configInput.targetId;
    await this.setLanguage();
    try {
      if (this.configInput.provider == 'api') {
        this.jsonProvider = new APIProvider(this.configInput);
        this.videoMeta = await this.jsonProvider.init();
      } else if (this.configInput.videoMeta === undefined || this.configInput.videoMeta == null) {
        this.jsonProvider = new JSONProvider(this.configInput);
        this.videoMeta = await this.jsonProvider.init();
      } else if (this.configInput.videoMeta !== undefined) {
        this.videoMeta = this.configInput.videoMeta;
        try {
          if (this.videoMeta.analytics === undefined) {
            this.videoMeta['analytics'] = {realtime: {disabled: false}};
          } else if (this.videoMeta.analytics.realtime === undefined) {
            this.videoMeta.analytics['realtime'] = {disabled: false};
          }
        } catch (e) {
          // do nothing
        }
        this.providerUrl = this.configInput.providerUrl !== undefined && this.configInput.providerUrl !== null ? this.configInput.providerUrl : providerUrl;
        this.defaultJSON =
          this.configInput.defaultJSON !== undefined && this.configInput.defaultJSON
            ? this.configInput.defaultJSON
            : this.videoMeta.isLive
            ? await defaultJSON(this.videoMeta.user, this.configInput, this.providerUrl, this.videoMeta.streamNameLive)
            : await defaultJSON(this.videoMeta.user, this.configInput);
        this.jsonProvider = {defaultJSON: this.defaultJSON};
        /**
         * Funcionalidad que permite poner en el json del javascript al instanciar el player rutas de cdn por usuario
         */
        if (this.defaultJSON[this.videoMeta.user] !== undefined) {
          try {
            this.jsonProvider.defaultJSON.urls = this.defaultJSON[this.videoMeta.user].urls;
          } catch (e) {
            this.jsonProvider = {defaultJSON: this.defaultJSON};
          }
        }
        try {
          const regex = /^(https:|http:)?(\/\/)?[.a-z]+[.a-z0-9-]+/gm;
          if (this.videoMeta.isLive) {
            this.videoMeta.urls.hls = this.videoMeta.urls.hls.replace(regex, this.defaultJSON.urls[0]).replace('http:', 'https:');
          } else {
            this.videoMeta.urls.hls = this.videoMeta.urls.hls.replace(regex, this.defaultJSON.vod[0]).replace('http:', 'https:');
          }
        } catch (e) {
          // do nothing
        }
        try {
          delete this.playerConfig.cast;
        } catch (e) {
          // do nothing
        }
      } else {
        console.error('No provider type');
        return;
      }
    } catch (e) {
      console.error('No provider type', e);
      return;
    }

    if (!this.whiteListDomains.includes(this.currentReferrer)) {
      if (this.videoMeta.isActive === false) {
        this.error(Error.VIDEO_NOT_ACTIVE, null, Error.VIDEO_NOT_ACTIVE.text, `${this.assetsCDN}/images/${Error.VIDEO_NOT_ACTIVE.icon}`);
        return;
      }

      const validGeo = await this._isValidGeoBlocking();

      if (
        this.videoMeta.allowedDomains !== undefined &&
        this.videoMeta.allowedDomains &&
        this.videoMeta.allowedDomains.length &&
        !this._isValidReferrer
      ) {
        if (this.videoMeta.images.domainBlocked && this.videoMeta.images.domainBlocked.indexOf('live_block.png') < 0)
          this.error(Error.DOMAIN_BLOCKED, this.videoMeta.images.domainBlocked, null, null);
        else this.error(Error.DOMAIN_BLOCKED, null, Error.DOMAIN_BLOCKED.text, `${this.assetsCDN}/images/${Error.DOMAIN_BLOCKED.icon}`);
        return;
      } else if (!validGeo) {
        if (this.videoMeta.images.geoBlocked && this.videoMeta.images.geoBlocked.indexOf('live_block.png') < 0)
          this.error(Error.GEO_BLOCKING, this.videoMeta.images.geoBlocked, null, null);
        else this.error(Error.GEO_BLOCKING, null, Error.GEO_BLOCKING.text, `${this.assetsCDN}/images/${Error.GEO_BLOCKING.icon}`);
      } else {
        if (canConfigure) await this.configurePlayer();
      }
    } else {
      if (canConfigure) await this.configurePlayer();
    }
  }

  setInstantVideoCloudParams() {
    this.playerConfig.InstantVideoCloud = this.videoMeta;
    const jsonProvider = this.configInput.providerUrl !== undefined ? this.configInput.providerUrl : providerUrl;
    this.playerConfig.InstantVideoCloud['jsonProvider'] = `${jsonProvider}/definition/fileover/${this.videoMeta.user}.json`;
  }

  async configurePlayer() {
    try {
      this.setKLog();
      this.setSource();
      this.configureHls();
      this.setWatermark();
      this.setShare();
      this.setStoryboard();
      if (this.configInput.enableClipping === undefined || this.configInput.enableClipping === false) {
        customizePlayer(this);
      }
      this.setInstantVideoCloudParams();
      this.setChat();
      if (!this.isOtt()) this.setTitle();
      this.setInfo();
      this.setQualities();
      this.configUI();
      this.configAbr();
      await this.setPlayer();
      // try{
      //   this.player._localPlayer.posterManager.show();
      // }catch(e){
      //   console.log(e);
      // }
    } catch (e) {
      console.debug(e);
      this.errorNotFound();
    }
  }

  setKLog() {
    try {
      // if (this.configInput.log !== undefined && this.configInput.log) {
      //   this.playerConfig.log.level = this.configInput.log;
      // }
      this.playerConfig.log.level = this.configInput.log ? this.configInput.log : 'DEBUG';
    } catch (e) {
      //do nothing
    }
  }

  async setPlayer() {
    await scripts(this.assetsCDN);
    if (this.currentReferrer && whiteListDomains.includes(this.currentReferrer)) {
      // do nothing
      await this.setAnalytics();
      await this.setIvcAds();
    } else {
      if (this.configInput.enableClipping === undefined || this.configInput.enableClipping === false) {
        await this.setAnalytics();
        await this.setIvcAds();
      }
    }

    if (this.configInput.enableClipping !== undefined && this.configInput.enableClipping === true) {
      await this.enableClipping();
    }
    hideLoading(this.configInput.targetId);
    navigator.sendBeacon = function () {};
    window.DEBUG_KALTURA_PLAYER = false;
    this.player = KalturaPlayer.setup(this.playerConfig);
    this._addBindings();
  }

  error(message: Error, image?: string, text?: string, icon?: url) {
    renderError(this.playerConfig.targetId, image, text, icon);
  }

  mergeConfig(): void {
    this.configInput = Object.assign(defaultInputConfig, this.configInput);
  }

  urlParams() {
    let path;
    if (document.location.hash) {
      path = document.location.hash.replace('#/', '/');
    } else {
      path = document.location.pathname;
    }
    let obj = {embed: null, id: null};
    const parts = path.split('/').filter(a => a.length && a != 'embed');
    if (parts.length == 1) {
      obj.embed = parts[0].toLowerCase();
    } else {
      obj.embed = parts[0].toLowerCase();
      obj.id = parts[1].toLowerCase();
    }
    try {
      const erParams = new RegExp('(/?|/&)log=([^&]+)');
      const parts = erParams.exec(document.location.href);
      if (parts && parts.length > 1 && parts[2] !== undefined && parts[2]) this.configInput.log = parts[2];
    } catch (e) {
      //do nothing
    }
    this.configInput = Object.assign(this.configInput, obj);
  }

  setWatermark() {
    try {
      let waterObj = {};
      if (this.videoMeta.watermark.isVisible !== undefined && this.videoMeta.watermark.isVisible) {
        let target = null;
        if (this.videoMeta.watermark.target !== undefined && this.videoMeta.watermark.target) {
          try {
            new URL(this.videoMeta.watermark.target);
            target = this.videoMeta.watermark.target;
          } catch (e) {
            target = `https://${this.videoMeta.watermark.target}`;
          }
        }
        waterObj = {
          img: this.videoMeta.watermark.src,
          url: target,
          placement: this.videoMeta.watermark.pos || 'top-left',
          timeout: 0
        };
      } else {
        waterObj = {};
        delete this.playerConfig.ui.components.watermark;
      }
      this.playerConfig.ui.components.watermark = waterObj;
    } catch (e) {
      this.videoMeta.watermark = {};
      delete this.playerConfig.ui.components.watermark;
    }
  }

  async setIvcAds() {
    try {
      this.playerConfig = await ads(this);
    } catch (e) {
      // do nothing;
    }
  }

  _create_UUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  setSource() {
    // this.videoMeta.isDvr = false;
    const source = {
      id: this._create_UUID(),
      poster:
        this.videoMeta.images !== undefined && this.videoMeta.images.principal !== undefined && this.videoMeta.images.principal
          ? this.videoMeta.images.principal
          : null,
      hls: [{url: this.videoMeta.urls.hls, mimetype: 'application/x-mpegurl', id: 1}],
      metadata: {
        name: this.videoMeta.basics.title,
        description: this.videoMeta.basics.description
      },
      // type: this.videoMeta.isAudio ? 'Audio' : this.videoMeta.isLive ? 'Live' : 'Vod',
      type: 'Audio',
      options: {
        forceRedirectExternalStreams: false,
        defaultJSON: this.jsonProvider.defaultJSON,
        isLive: this.videoMeta.isLive
      },
      dvr: !!this.videoMeta.isDvr
      // dvr: false
    };

    if(this.videoMeta.isLive && this.videoMeta.isAudio){
      source.type = 'Audio';
    }else{
      if(this.videoMeta.urls.hls.toLowerCase().indexOf('.m4a') > -1){
        source.type = 'Audio';
      }else{
        source.type = this.videoMeta.isLive ? 'Live' : 'Vod';
      }
    }

    if (this.configInput.startTime !== undefined && this.configInput.startTime) source['startTime'] = this.configInput.startTime;

    try {
      // get url without hash
      const url = document.location.href.split('#')[0];
      // get url params
      const erParams = new RegExp('(/?|/&)autoplay=([^&]+)');
      const parts = erParams.exec(url);
      if (parts && parts.length > 1 && parts[2] !== undefined && parts[2]) {
        this.videoMeta.autoPlay = parts[2] === 'true';
      }
    } catch (e) {
      //do nothing
      console.log(e);
    }

    try {
      if (this.configInput.playback && this.configInput.playback.autoplay !== undefined) this.videoMeta.autoPlay = this.configInput.playback.autoplay;
    } catch (e) {
      console.log(e);
    }

    this.playerConfig.playback.autoplay = this.videoMeta.autoPlay ? true : false;

    if (this.videoMeta.urls.mp4 !== undefined && this.videoMeta.urls.mp4.length)
      source['progressive'] = this.videoMeta.urls.mp4.map(a => {
        return {url: a, mimetype: 'video/mp4'};
      });
    if (this.videoMeta.captions) source['captions'] = this.videoMeta.captions;
    this.playerConfig.sources = source;
  }

  setShare() {
    try {
      this.playerConfig.ui.components.share = {
        enable: Object.keys(this.videoMeta.share).filter(o => this.videoMeta.share[o] === true).length,
        embedUrl: document.location.href,
        shareUrl: document.location.href,
        showSource: this.videoMeta.share.source === true,
        downloadVideo: !this.videoMeta.isLive && this.videoMeta.share.download ? this.videoMeta.urls.mp4[this.videoMeta.urls.mp4.length - 1] : null,
        facebook: this.videoMeta.share.facebook,
        twitter: this.videoMeta.share.twitter
      };
    } catch (e) {
      // do nothing
    }
  }

  setStoryboard() {
    if (!this.videoMeta.isLive || this.videoMeta.isDvr) {
      this.playerConfig.ui.components.seekbar.ivc.cdn = this.jsonProvider.defaultJSON.storage[0];
      this.playerConfig.ui.components.seekbar.ivc.user = this.videoMeta.user;
      this.playerConfig.ui.components.seekbar.ivc.id = this.configInput.id;
      if (this.videoMeta.isDvr) {
        this.playerConfig.ui.components.seekbar.ivc.id = this.videoMeta.streamNameLive;
      }
    }
    this.playerConfig.ui.components.seekbar.ivc.isLive = this.videoMeta.isLive;
    this.playerConfig.ui.components.seekbar.ivc.isDvr = this.videoMeta.isDvr;
    this.playerConfig.ui.components.seekbar.ivc.isAudio = this.videoMeta.isAudio;
  }

  setChat() {
    try {
      if (this.videoMeta.chatEnabled === true) {
        this.playerConfig.plugins['IvcChat'] = {
          playerId: this.videoMeta.playerId,
          ss: this.videoMeta.playerSS,
          url: this.chatUrl
        };
      } else {
        try {
          delete this.playerConfig.plugins['IvcChat'];
        } catch (e) {
          // do nothing
        }
      }
    } catch (e) {
      //do nothing
      delete this.playerConfig.plugins['IvcChat'];
    }
  }

  setTitle() {
    try {
      document.title = this.videoMeta.basics.title;
    } catch (e) {
      //do nothing
    }
    if (!this.videoMeta.isLive) {
      try {
        if (this.videoMeta.customization.showTitle === true) this.playerConfig.plugins['IvcTitle'] = {title: this.videoMeta.basics.title};
        else delete this.playerConfig.plugins['IvcTitle'];
      } catch (e) {
        delete this.playerConfig.plugins['IvcTitle'];
      }
    } else {
      delete this.playerConfig.plugins['IvcTitle'];
    }
  }

  setInfo() {
    let brandingImage = `${this.assetsCDN}/images/ivc.png`;
    let brandingTarget = `https://www.multistream.tv`;
    // if (this.videoMeta.user == 'telemedellintv') {
    //   brandingImage = `${this.assetsCDN}/images/telemedellin.png`;
    //   brandingTarget = `https://www.telemedellin.tv/`;
    // } else if (this.videoMeta.user == 'teleantioquia') {
    //   brandingImage = `${this.assetsCDN}/images/teleantioquia.png`;
    //   brandingTarget = `https://www.telemedellin.tv/`;
    // }
    try {
      if (this.videoMeta.branding.src !== null && this.videoMeta.branding.src.length) {
        brandingImage = this.videoMeta.branding.src;
      }
      if (this.videoMeta.branding.url !== null && this.videoMeta.branding.url.length) {
        brandingTarget = this.videoMeta.branding.url;
      }
      if (this.videoMeta.showInfoBtn)
        this.playerConfig.plugins['Info'] = {
          playerVersion: 'IVC PLAYER - 4.1',
          playerCDNName: 'Multi-CDN',
          brandingImage: brandingImage,
          brandingTarget: brandingTarget,
          cdnImage: `${this.assetsCDN}/images/cdn.png`,
          cdnTarget: `https://www.multistream.tv`,
          assetsCDN: this.assetsCDN
        };
      else delete this.playerConfig.plugins['Info'];
    } catch (e) {
      //do nothing
      delete this.playerConfig.plugins['Info'];
      this.logger.debug(e);
      delete this.playerConfig.plugins['Info'];
    }
  }

  configureHls() {
    if (this.videoMeta.isLive && this.videoMeta.isDvr) {
      this.playerConfig.playback.options.html5.hls = hlsDvrConfig;
    } else if (this.videoMeta.isLive && !this.videoMeta.isDvr) {
      this.playerConfig.playback.options.html5.hls = hlsLiveConfig;
    } else {
      this.playerConfig.playback.options.html5.hls = hlsVodConfig;
    }
  }

  async setAnalytics(): Promise<any> {
    this.playerConfig = await analytics(this);
    return;
  }

  get _isValidReferrer() {
    const allowedDomains = _.uniq(_.union(defaultAllowedDomains, this.videoMeta.allowedDomains))
      .filter(a => a.length)
      .map(a => a.toLowerCase())
      .map(a => a.replace(/^www\./, ''));
    const referrer = this.currentReferrer;
    const index = allowedDomains.findIndex(a => a == referrer);
    return index > -1;
  }

  get currentReferrer() {
    let referrer = null;
    try {
      const regexp = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/; // eslint-disable-line
      if (parent !== window) {
        referrer = document.referrer.match(regexp)[1];
      } else {
        referrer = document.location.href.match(regexp)[1];
      }
    } catch (e) {
      referrer = null;
    }
    return referrer;
  }

  async _isValidGeoBlocking() {
    if (this.videoMeta.geoBlocking === undefined || this.videoMeta.geoBlocking.length == 0) return true;
    try {
      const country = await this.getCountry();
      if (country == null) return false;
      const isValid = this.videoMeta.geoBlocking.findIndex(c => c == country) == -1;
      return isValid;
    } catch (e) {
      return false;
    }
  }

  async getCountry() {
    let co = localStorage.getItem('player_country');
    if (co == null) {
      return await this.reqCountry();
    } else {
      co = JSON.parse(co);
      if (Date.now() - co.time > 120000) {
        return await this.reqCountry();
      } else return co.country;
    }
  }

  async reqCountry() {
    let country;
    try {
      const req = await fetch(geoIpRealtime);
      country = await req.text();
    } catch (e) {
      try {
        const geoData = await Http.execute(`https://geoip-js.com/geoip/v2.1/country/me?referrer=${geoIpReferrer}`, null, 'GET');
        country = geoData.country.iso_code;
      } catch (e) {
        country = null;
      }
    }
    const co = {
      country: country,
      time: Date.now()
    };
    localStorage.setItem('player_country', JSON.stringify(co));
    return country;
  }

  _addBindings(): void {
    this.player._eventManager.listen(this.player, this.player.Event.FIRST_PLAY, () => {
      try {
        if ('mediaSession' in navigator) {
          const obj = {
            title: this.videoMeta.basics.title,
            artist: '',
            album: '',
            artwork: [{src: this.videoMeta.images.principal || `${this.assetsCDN}/images/ivc.png`, type: 'image/png'}]
          };
          navigator.mediaSession.metadata = new MediaMetadata(obj);
          navigator.mediaSession.playbackState = 'none';
          if (this.videoMeta.isLive == false) {
            navigator.mediaSession.setActionHandler('seekbackward', () => {
              this.player._localPlayer.currentTime = this.player._localPlayer.currentTime - 10;
            });
            navigator.mediaSession.setActionHandler('seekforward', () => {
              this.player._localPlayer.currentTime = this.player._localPlayer.currentTime + 10;
            });
          }

          navigator.mediaSession.setActionHandler('play', () => {
            this.player.play();
          });
          navigator.mediaSession.setActionHandler('pause', () => {
            this.player.pause();
          });
        }
      } catch (e) {
        //do nothing
      }
      try {
        if (this.enableClipping === true) {
          const levels = this.player._localPlayer._engine._mediaSourceAdapter._hls.levels;
          this.player._localPlayer._engine._mediaSourceAdapter._hls.currentLevel = levels.length - 1;
        }
      } catch (e) {
        //do nothing
      }
    });
    this.player._eventManager.listen(this.player, 'internalReset', data => {
      this.internalReset(data.payload);
    });
    this.player._eventManager.listen(this.player, this.player.Event.PLAYER_DESTROY, () => {
      // console.log('dispatch player destroy');
    });
    try {
      window.addEventListener('hashchange', () => {
        document.location.reload();
      });
    } catch (e) {
      // do nothing
    }

    try {
      window.addEventListener('message', event => {
        if (event.data !== undefined && event.data.event !== undefined) {
          switch (event.data.event) {
            case 'play':
              this.player.play();
              break;
            case 'pause':
              this.player.pause();
              break;
            case 'remote-tv-control':
              document.dispatchEvent(new KeyboardEvent('keydown', {key: event.data.key}));
              break;
          }
        }
      });
    } catch (e) {
      // do nothing
    }

    try {
      if (parent !== window) {
        const events = [
          this.player.Event.ERROR,
          this.player.Event.ENDED,
          this.player.Event.PAUSE,
          this.player.Event.PLAY,
          this.player.Event.SEEKED,
          this.player.Event.TIME_UPDATE,
          this.player.Event.UI.USER_ENTERED_FULL_SCREEN,
          this.player.Event.UI.USER_EXITED_FULL_SCREEN,
          this.player.Event.UI.UI_VISIBILITY_CHANGED
        ];
        for (let event of events) {
          this.player._eventManager.listen(this.player, event, e => {
            window.top.postMessage(
              {
                event: event,
                currentTime: this.player.currentTime,
                duration: this.player.duration,
                progress: this.player.duration ? this.player.currentTime / this.player.duration : 0,
                isLive: this.videoMeta.isLive,
                ...(event == this.player.Event.UI.UI_VISIBILITY_CHANGED ? {visible: e.payload.visible} : {})
              },
              '*'
            );
          });
        }
      }
    } catch (e) {
      console.error(e);
    }

    try {
      window.onbeforeunload = () => {
        this.logger.debug('unload');
        Object.keys(localStorage)
          .filter(k => k.indexOf('thumbs_') > -1)
          .forEach(k => localStorage.removeItem(k));
      };
    } catch (e) {
      // do nothing
    }
    this.player._eventManager.listen(this.player, 'playkit-ui-uivisibilitychanged', e => {
      if (!e.payload.visible) {
        if (document.fullscreenElement || (this.isOtt() && this.configInput.playback.ui.view != 'ott-without-ui')) {
          document.body.style.cursor = 'none';
        }
      } else {
        // show mouse cursor
        document.body.style.cursor = 'auto';
      }
    });

    this.player._eventManager.listen(this.player, this.player.Event.ENDED, () => {
      document.body.style.cursor = 'auto';
    });
    this.player._eventManager.listen(this.player, this.player.Event.PAUSE, () => {
      document.body.style.cursor = 'auto';
    });
    // LOADED_DATA
    // this.player._eventManager.listen(this.player, this.player.Event.LOADED_DATA, () => {
    //   if (this.getView() === 'tv') this.remoteTvControl();
    // });
    // console.log('fake', FakeEvent);
    document.addEventListener('tvc-seek', e => {
      // console.log('tvc-seek', e);
      try{
        document.getElementsByClassName('playkit-seek-bar')[0].classList.add('playkit-hover');
        this.player.currentTime = this.player.currentTime + e.detail;
        this.player._localPlayer.dispatchEvent(new FakeEvent('virtualSeek', {time: this.player.currentTime}));
        try {
          clearTimeout(this.timeoutHover);
        } catch (e) {
          // do nothing
        }
        this.timeoutHover = setTimeout(() => {
          document.getElementsByClassName('playkit-seek-bar')[0].classList.remove('playkit-hover');
        }, 2000);
      }catch(e){
        // do nothing
      }
    });
  }

  setQualities() {
    if (this.videoMeta.isLive) {
      this.playerConfig['customLabels'] = {
        qualities: videoTrack => {
          if (videoTrack.width !== undefined && videoTrack.width) {
            const _width = videoTrack.width;
            const _height = videoTrack._height;
            if (_width == undefined) return;
            let label;
            if (_width >= 3000) label = 'HD-4K';
            else if (_width >= 2000) label = 'HD-2K';
            else if (_width >= 1920) label = '1080p';
            else if (_width >= 1280) label = '720p';
            else if (_width == 854) label = '480p';
            else if (_width == 640) label = '360p';
            else if (_width == 426) label = '240p';
            else label = Math.round(_height) + 'p';
            return label;
          } else {
            const _bitrate = parseInt(videoTrack.bandwidth / 1000);
            let label = null;
            if (_bitrate < 151) label = '120p';
            else if (_bitrate < 301) label = '180p';
            else if (_bitrate < 501) label = '240p';
            else if (_bitrate < 751) label = '360i';
            else if (_bitrate < 901) label = '360p';
            else if (_bitrate < 1501) label = '480p';
            else if (_bitrate < 2251) label = '720p';
            else if (_bitrate < 4501) label = '1080p';
            else if (_bitrate < 6010) label = 'HD-2K';
            else if (_bitrate >= 6010) label = 'HD-4K';
            return label;
          }
        }
      };
    } else {
      this.playerConfig['customLabels'] = {
        qualities: videoTrack => {
          const _width = videoTrack.width;
          if (_width == undefined) return;
          let label;
          if (_width >= 3000) label = 'HD-4K';
          else if (_width >= 2000) label = 'HD-2K';
          else if (_width >= 1920) label = '1080p';
          else if (_width >= 1280) label = '720p';
          else if (_width == 854) label = '480p';
          else if (_width == 640) label = '360p';
          else if (_width == 426) label = '240p';
          else label = Math.round(_width / (16 / 9)) + 'p';
          return label;
        }
      };
    }
  }

  async setLanguage() {
    let locale = 'en';
    try {
      const navLnag = getBrowserLocales({languageCodeOnly: true});
      if (navLnag.length) locale = navLnag[0];
    } catch (e) {
      // do nothing
    }
    try {
      const langData = await Http.execute(`${this.assetsCDN}/translate/${locale}.i18n.json`, null, 'GET');
      this.playerConfig.ui['locale'] = locale;
      this.playerConfig.ui['translations'] = langData;
      window.translations = langData[Object.keys(langData)[0]];
    } catch (e) {
      // do nothing
    }
  }

  isOtt() {
    let isOttOrApp = false;
    try {
      if (this.configInput.playback && this.configInput.playback.ui && this.configInput.playback.ui.view) {
        isOttOrApp = this.configInput.playback.ui.view !== undefined;
        return isOttOrApp;
      }
      return false;
    } catch (e) {
      // do nothing
      return false;
    }
  }

  configUI() {
    try {
      if (this.configInput.hoverTimeout !== undefined) {
        this.videoMeta.customization.hideBarTimeout = this.configInput.hoverTimeout;
        this.playerConfig.ui.hoverTimeout = this.configInput.hoverTimeout;
      }
    } catch (e) {
      // do nothing
    }
    if (this.videoMeta.isAudio !== undefined && this.videoMeta.isAudio == true) {
      this.playerConfig.ui.hoverTimeout = 24 * 3600 * 1000;
    } else {
      try {
        if (this.videoMeta.customization.hideBarTimeout !== undefined)
          this.playerConfig.ui.hoverTimeout = this.videoMeta.customization.hideBarTimeout;
        else this.playerConfig.ui.hoverTimeout = 5000;
      } catch (e) {
        // do nothing
      }
    }

    try {
      if (this.videoMeta.customization.showCCBtn === true) {
        this.playerConfig.ui.components.seekbar['showCCBtn'] = true;
      } else {
        this.playerConfig.ui.components.seekbar['showCCBtn'] = false;
      }
    } catch (e) {
      // do nothing
    }
    if (this.isOtt() && this.configInput.hoverTimeout === undefined) {
      this.playerConfig.ui.hoverTimeout = 5000;
      this.playerConfig.ui.components['fullscreen'] = {disableDoubleClick: true};
    }
    try {
      const url = document.location.href.split('#')[0];
      // get url params
      const erParams = new RegExp('(/?|/&)view=([^&]+)');
      const parts = erParams.exec(url);
      if (parts && parts.length > 1 && parts[2] !== undefined && parts[2]) {
        this.playerConfig.ui.view = parts[2];
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (this.configInput.playback && this.configInput.playback.ui && this.configInput.playback.ui.view) {
        this.playerConfig.ui.view = this.configInput.playback.ui.view;
      }
    } catch (e) {
      // do nothing
    }
  }

  configAbr() {
    try {
      if (!this.videoMeta.isLive) this.playerConfig.abr.capLevelToPlayerSize = false;
    } catch (e) {
      // do nothing
    }
  }

  async enableClipping() {
    try {
      if (this.configInput.enableClipping !== undefined && this.configInput.enableClipping === true) {
        await load(`${this.assetsCDN}/playkit-clipping.js`);
        this.playerConfig.plugins['Clipping'] = {
          user: this.videoMeta.user,
          assetsCDN: this.assetsCDN,
          id: !this.videoMeta.isLive || this.videoMeta.isDvr ? (!this.videoMeta.isDvr ? this.configInput.id : this.videoMeta.streamNameLive) : null,
          isLive: this.videoMeta.isLive,
          uploadInfo: JSON.stringify(this.videoMeta.clipping)
        };
        this.playerConfig.playback.autoplay = true;
        this.playerConfig.ui.hoverTimeout = 24 * 3600 * 1000;
        this.playerConfig.ui.components.seekbar.ivc.disabled = false;
        this.playerConfig.ui.components['fullscreen'] = {disableDoubleClick: true};
        this.playerConfig.playback.muted = true;
        // forzamos calidad mas baja
        this.playerConfig.abr = {
          enabled: true,
          capLevelOnFPSDrop: true,
          capLevelToPlayerSize: true,
          restrictions: {
            minBitrate: 5000,
            maxBitrate: Infinity
          }
        };
        // this.playerConfig.playback.options.html5.hls.currentLevel = 2;
      } else {
        try {
          delete this.playerConfig.plugins['Clipping'];
        } catch (e) {
          //do nothing
        }
      }
    } catch (e) {
      this.logger.error(e);
      // do nothing
    }
  }

  destroy() {
    // delete this.playerConfig;
    try {
      this.player.destroy();
    } catch (e) {
      // do nothing
    }

    try {
      // unbind all events
      this.player._eventManager.removeAll();
    } catch (e) {
      // do nothing
    }

    this.playerConfig = defaultConfig;
    this.videoMeta = this.configInput.videoMeta;
  }

  async internalReset(payload: any) {
    let config = this.player.config;
    config.sources.options.defaultJSON.urls = payload;
    try {
      const regex = /^(https:|http:)?(\/\/)?[.a-z]+[.a-z0-9-]+/gm;
      config.sources.hls[0].url = config.sources.hls[0].url.replace(regex, payload[0]).replace('http:', 'https:');
    } catch (e) {
      console.error(e);
      // do nothing
    }
    this.player.reset();
    const playkitPlayer = document.getElementById(this.configInput.targetId).getElementsByClassName('playkit-player')[0];
    const display = playkitPlayer.style.display;
    playkitPlayer.style.display = 'none';
    this.player.configure(config);
    setTimeout(() => {
      this.player.play();
      setTimeout(() => {
        playkitPlayer.style.display = display;
      }, 500);
    }, 500);
  }

  // remoteTvControl() {
  //   const control = new TVRemoteControl(this.player);
  //   control.init();
  // }

  getView() {
    return this.playerConfig.ui.view;
  }

  debounce(func, wait, immediate) {
    let timeout;
    return function () {
      let context = this,
        args = arguments;
      let later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait || 200);
      if (callNow) func.apply(context, args);
    };
  }

  get uiVisible() {
    try {
      const playkitPlayer = document.getElementById(this.configInput.targetId).getElementsByClassName('playkit-player')[0];
      return playkitPlayer.classList.contains('playkit-hover');
    } catch (e) {
      return false;
    }
  }

  showUI() {
    const playkitPlayer = document.getElementById(this.configInput.targetId).getElementsByClassName('playkit-player')[0];
    playkitPlayer.classList.add('playkit-hover');
    if(this.player.paused){
      playkitPlayer.classList.add('playkit-state-paused');
    }
  }

  hideUI() {
    const playkitPlayer = document.getElementById(this.configInput.targetId).getElementsByClassName('playkit-player')[0];
    playkitPlayer.classList.remove('playkit-hover');
    playkitPlayer.classList.remove('playkit-state-paused');
  }
}

export {PlayerManager};
